<script lang="ts">
    import SuggestionCard from '@/Components/SuggestionCard.svelte';
    import ExploreTab from '@/Components/explore/exploreTab.svelte';
    import type { User } from '@/interfaces/general';

    export let authUser: User;
    export let isMobile;
    export let shorts;
    export let suggestions;
    export let hide_suggestions_slider;
    export let feed_suggestions_card_per_page;

    let chunkSize = isMobile ? 2 : feed_suggestions_card_per_page;
    const chunkArray = (array: string | any[], size: number) => {
        const chunkedArray = [];
        for (let i = 0; i < array.length; i += size) {
            chunkedArray.push(array.slice(i, i + size));
        }
        return chunkedArray;
    };
    $: chunkedProfiles = chunkArray(suggestions, chunkSize);
</script>

<div class="container">
    <div class="row">
        <div class="col-12 col-sm-12 col-lg-8 col-md-7 second p-0">
            <div class="d-flex d-md-none feed-mobile-search neutral-bg fixed-top-m">
                <!-- @include('elements.search-box') -->
            </div>

            <div class="">
                <!-- @include('elements.message-alert', ['classes' => 'pt-4 pb-4 px-2']) -->
                <div class="">
                    <ExploreTab {authUser} />
                    <h4 class="p-3">Shorts here!</h4>
                    <div>
                        <!-- @foreach ($shorts as $short) -->
                        <!-- {{-- implement snap scroll --}} -->

                        {#each shorts as short}
                            <div class="">
                                <div class="">
                                    <video src="{short['media-src']}">
                                        <track
                                            kind="captions"
                                            src="{short['captions-src']}"
                                            srclang="en"
                                            label="English"
                                        />
                                    </video>
                                </div>
                            </div>
                        {/each}

                        <!-- @endforeach -->
                    </div>
                </div>
                <div class="the-last-div"></div>

                <div class="d-flex align-items-center justify-content-center posts-loading-indicator">
                    <div class="spinner d-none py-3">
                        <div class="spinner-border text-primary" role="status">
                            <span class="sr-only">{'Loading...'}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="col-12 col-sm-12 col-md-5 col-lg-4 first border-left order-0 min-vh-100 suggestions-wrapper d-none d-md-block pb-5 pt-4"
        >
            <div class="feed-widgets">
                <div class="mb-4">
                    <!-- @include('elements.search-box') -->
                </div>

                {#if !hide_suggestions_slider}
                    <div
                        class="suggestions-box{isMobile ? '-mobile' : ''} rounded-lg border px-2 {isMobile
                            ? 'pb-1 pt-3'
                            : 'py-4'}"
                    >
                        <div class="d-flex justify-content-between suggestions-header mb-3 px-1">
                            <h5 class="card-title mb-0 pl-2">
                                {'Suggestions'}
                            </h5>
                            <div class="d-flex">
                                <div class="d-flex"></div>
                                <div class="d-flex">
                                    <span
                                        class="mr-xl-3 pointer-cursor mr-2"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="{'Free account only'}"
                                    >
                                        <!-- @include('elements.icon',['icon'=>'pricetag-outline','variant'=>'medium','centered'=>false]) -->
                                    </span>

                                    <span
                                        class="mr-xl-3 pointer-cursor mr-2"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="{'Refresh suggestions'}"
                                    >
                                        <!-- @include('elements.icon',['icon'=>'refresh','variant'=>'medium','centered'=>false]) -->
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="suggestions-content">
                            {#if suggestions.length > 0}
                                <div class="swiper-container mySwiper">
                                    <div class="swiper-wrapper">
                                        {#each chunkedProfiles as profilesSet}
                                            <!-- // ($profiles->chunk( //
                                        GenericHelper::isMobileDevice() ? 2
                                        :
                                        (int)getSetting('feed.feed_suggestions_card_per_page')
                                        // ) as $profilesSet) -->

                                            <div class="swiper-slide px-1">
                                                {#each profilesSet as profile}
                                                    <SuggestionCard
                                                        {profile}
                                                        isListMode="{false}"
                                                        isListManageable="{false}"
                                                    />
                                                {/each}

                                                <!-- @foreach ($profilesSet as $k => $profile)

                                                @include('elements.feed.suggestion-card',['profile'=> $profile ,'isListMode' =>false, 'isListManageable' =>false])

                                            @endforeach -->
                                            </div>
                                        {/each}
                                    </div>
                                </div>
                                <div class="d-flex align-items-center justify-content-center">
                                    <div class="swiper-pagination mt-4"></div>
                                </div>
                            {:else}
                                <p class="text-center">
                                    {'No suggestions available'}
                                </p>
                            {/if}
                        </div>

                        <!-- @include('elements.feed.suggestions-wrapper',['profiles'=>$profiles]) -->
                    </div>
                {/if}

                <!-- @if (!getSetting('feed.hide_suggestions_slider'))
                @include('elements.feed.suggestions-box', [ 'profiles' =>
                $suggestions, 'isMobile' => false, ]) @endif -->

                <!-- @if (getSetting('custom-code-ads.sidebar_ad_spot'))
                    <div class="mt-4">
                        {!! getSetting('custom-code-ads.sidebar_ad_spot') !!}
                    </div>
                @endif -->
            </div>
        </div>
    </div>
    <!-- @include('elements.checkout.checkout-box') -->
</div>
